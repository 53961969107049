<template>
  <div class="new-task">
      <input v-model="name" 
             @keydown.enter="add" 
             type="text"
             placeholder="Nova tarefa"
             class="form-element" />
      <button @click="add" 
              type="button"
              class="form-element btn">+</button>
  </div>
</template>

<script>
export default {
    data() {
        return {
            name: ''
        }
    },
    methods: {
        add() {
            if (this.name.length) {
                this.$emit('taskAdded', { name: this.name }),
                this.name = ''
            }
        }
    }
}
</script>

<style>
    .new-task {
        margin: 35px;
    }

    .form-element {
        outline: none;
        font-size: 2rem;
        border: 1px solid #FFF;
        padding: 5px 10px 8px;
        color: #FFF;
    }

    .form-element .btn {
        cursor: pointer;
    }

    input.form-element {
        width: 500px;
        background: #FFF2;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    button.form-element {
        border-left: none;
        background-color: #2196F3;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
</style>